<template>
  <div>
    <CRow class="justify-content-center">
      <CCard
        color="white"
        text-color="black"
        class="text-center col-md-12 "
        body-wrapper
      >
        <CRow>
          <CCol md="12">
            <h2 class="font-weight-normal">{{ $t('sendVoucher') }}</h2>
            <p class="text-description">
             {{ $t('sendEVoucher') }}
              <br />
              {{ $t('createEVoucher2') }}
            </p>
            <CAlert
              color="success"
              v-if="isSaveSuccess !== ''"
              :show="showtime"
            >
              {{ isSaveSuccess }}
            </CAlert>
          </CCol>
        </CRow>
        <hr />
        <p class="text-danger font-weight-normal text-left">{{ alert }}</p>
        <CRow>
          <CCol md="6">
            <CRow>
              <CCol md="12">
                <div class="form-group">
                  <p class="text-left font-weight-normal">
                    {{ $t('selectVoucher') }}
                    <span class="text-danger ">*</span>
                  </p>
                  <hr />
                  <CJumbotron
                    class="pointer"
                    v-if="voucherObjectId === ''"
                    @click="voucherModal = true"
                  >
                    + {{ $t('selectVoucher') }}
                  </CJumbotron>
                  <CCard
                    border-color="greyborder"
                    v-else-if="voucherObjectId !== ''"
                    @click="voucherModal = true"
                  >
                    <table aria-describedby="">
                      <tr>
                        <th
                          scope="col"
                          style="background-color:#ebedef;width:35%"
                        >
                          <img alt="" class="img-fluid" :src="voucher.imgUrl" />
                        </th>
                        <td>
                          <CCardBody class="text-left text-black">
                            <strong>
                              {{ voucher.title }}
                            </strong>
                            <hr />
                            <p class="text-description">
                              {{ voucher.description }}
                            </p>
                            <p class="text-grey">
                              <strong>{{ $t('voucherValue') }} : </strong> {{ voucher.value }} {{$t('baht')}}
                            </p>
                            <p
                              v-if="voucher.expirationAt !== ''"
                              class="font-weight-normal text-grey"
                            >
                              {{ $t('periodAfterRecivedVoucher') }}
                              {{ voucher.expirationAt }} {{ $t('days') }}
                            </p>
                            <p v-else-if="voucher.expirationAt === ''">
                              <strong>{{ $t('startFrom') }} : </strong>
                              {{ startAtShow(voucher.startAt) }} -
                              {{ endAtShow(voucher.endAt) }}
                            </p>
                          </CCardBody>
                        </td>
                      </tr>
                    </table>
                  </CCard>
                </div>
              </CCol>
            </CRow>

            <p class="text-left font-weight-normal">
              {{ $t('selectMemberBySearch') }}
              <span class="text-danger font-weight-normal">*</span>
            </p>
            <hr />

            <CRow class="mb-4">
              <CCol lg="9" md="9" sm="8">
                <div>
                  <CInput
                    v-model.trim="keyword"
                    class="cursor"
                    :placeholder="$t('searchMemberCRM')+' ...'"
                    @input="getMember()"
                  />
                </div>
              </CCol>
              <CCol lg="3" md="3" sm="4">
                <CButton v-on:click="getMember()" block color="success">
                  {{$t('search')}}
                </CButton>
              </CCol>
            </CRow>
            <CRow v-if="memberObjectId === ''">
              <CCol md="12">
                <CListGroup v-if="loadingButton == true">
                  <CListGroupItem
                    v-for="(item, index) in memberdata"
                    :key="index"
                    class="list-group-item-action cursor pointer"
                  >
                    <table style="width: 100%" aria-describedby="">
                      <tr @click="memberDetail(item)">
                        <th scope="col" class="text-left">
                          <tr>
                            <span class="font-weight-normal text-black">
                              {{ item.name }}
                              <img
                                alt=""
                                v-if="item.isMember === 'line'"
                                :src="linemember"
                                class="img-fluid"
                                width="20px"
                              />
                            </span>
                          </tr>
                          <tr>
                            <h6 class="text-black">
                              {{ item.email }}
                            </h6>
                          </tr>
                        </th>

                        <td style="width: 50%" class="text-right">
                          <span class="text-black text-right">
                            <strong> {{ $t('tel') }} : </strong> {{ item.phone }}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </CListGroupItem>
                </CListGroup>
                <CListGroupItem v-if="loadingButton == false">
                  <div class="text-center" width="100%">
                    <button
                      class="btn"
                      type="button"
                      disabled
                      style="color: darkcyan"
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      >
                      </span>
                      {{ $t('loading') }} 
                    </button>
                  </div>
                </CListGroupItem>
                <br />
              </CCol>
            </CRow>
            <CRow v-if="memberObjectId !== ''" style="height:300px">
              <CCol md="12">
                <div class="form-group">
                  <CCard border-color="greyborder">
                    <table aria-describedby="">
                      <tr>
                        <th
                          scope="col"
                          style="background-color:#ebedef;width:35%"
                        >
                          <img alt="" class="img-fluid" :src="avatarUrl" />
                        </th>
                        <td>
                          <CCardBody class="text-left text-grey">
                            <h3 class="text-black">
                              {{ member.name }}
                              <img
                                alt=""
                                v-if="member.isMember === 'line'"
                                :src="linemember"
                                class="img-fluid"
                                width="20px"
                              />
                            </h3>

                            <hr />
                            <p><strong>{{ $t('email') }} : </strong>{{ member.email }}</p>
                            <p>
                              <strong>{{ $t('tel') }} : </strong>
                              {{ member.phone }}
                            </p>
                            <p>
                              <strong>{{ $t('member.loyaltyPoint') }} : </strong>
                              {{ member.point }} {{ $t('point') }}
                            </p>
                          </CCardBody>
                        </td>
                      </tr>
                    </table>
                  </CCard>
                </div>
              </CCol>
            </CRow>
          </CCol>

          <CCol md="6">
            <p class="text-left font-weight-normal">
              {{ $t('payment') }}
            </p>
            <hr />
            <CRow>
              <CCol md="12">
                <div class="form-group row">
                  <label class="col-sm-3 text-left text-grey">
                    <span>
                      {{ $t('paymentMethod') }}
                    </span>
                  </label>
                  <div class="col-sm-9">
                    <CSelect
                      v-model="paymentMethod"
                      :placeholder="$t('paymentMethodDialog')+' ...'"
                      :options="getPaymentMethodOptions()"
                      :value.sync="paymentMethod"
                    />
                  </div>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol md="12" v-if="paymentMethod === 'other'">
                <div class="form-group row">
                  <label class="col-sm-3 text-left text-grey"></label>
                  <div class="col-sm-9">
                    <CInput
                      v-model="paymentMethodOther"
                      :placeholder="$t('customPaymentMethod') + ' ...'"
                    />
                  </div>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol md="12">
                <div class="form-group row">
                  <label class="col-sm-3 text-left text-grey">
                    <span>
                      {{ $t('paymentAmount') }}
                    </span>
                  </label>
                  <div class="col-sm-9">
                    <CInput
                      type="number"
                      v-model="amount"
                      :placeholder="$t('inputPaymentAmount')+' ...'"
                    />
                  </div>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol md="12">
                <div class="form-group row">
                  <label class="col-sm-3 text-left text-grey">
                    <span>
                      {{ $t('additionalNote') }}
                    </span>
                  </label>
                  <div class="col-sm-9">
                    <CTextarea
                      v-model="note"
                      :placeholder="$t('additionalNoteDialog')"
                    />
                  </div>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol md="12">
                <div class="form-group row">
                  <label class="col-sm-3  text-left text-grey">
                    <span>
                      {{ $t('attachFile') }}
                    </span>
                  </label>
                  <div class="col-sm-9">
                    <CInputFile
                      class="text-left"
                      custom
                      :placeholder="$t('attachFileDialog')"
                      @change="onFileSelect"
                    />
                  </div>
                </div>
                <div>
                  <img alt="" v-if="url" :src="url" width="50%" />
                </div>
                <br />
              </CCol>
            </CRow>
            <p class="text-left font-weight-normal">
              {{ $t('condition') }}
            </p>
            <hr />
            <CRow>
              <CCol md="12">
                <div class="form-group">
                  <CTextarea
                    class="text-black"
                    v-model="remark"
                    :placeholder="$t('voucherDetailPlaceholder')"
                    rows="5"
                  />
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CButton block color="success" class="px-4" @click="handlePopup()">
          {{ $t('sendVoucher') }}
        </CButton>
      </CCard>
    </CRow>

    <CModal
      :show.sync="popupModal"
      color="success"
      :title="$t('sendVoucher')"
      centered
      header="false"
      :footer="footer"
    >
      <CRow>
        <CCol md="12">
          <h5 class="text-center text-black">
           {{  $t('confirmToSend') }} {{ voucher.title }} {{  $t('confirmToSendTo') }} {{ member.name }}
          </h5>
          <br />
          <CCard border-color="greyborder">
            <table aria-describedby="">
              <tr>
                <th
                  scope="col"
                  style="background-color:#ebedef;width:35%"
                  class="text-center"
                >
                  <img alt="" class="img-fluid" :src="voucher.imgUrl" />
                </th>
                <td>
                  <CCardBody class="text-left text-black">
                    <h4>
                      {{ voucher.title }}
                    </h4>
                    <hr />
                    <p class="text-grey">
                      <strong>{{ $t('voucherValue') }} : </strong> {{ voucher.value }} {{ $t('baht') }}
                    </p>
                    <p
                      v-if="voucher.expirationAt !== ''"
                      class="font-weight-normal text-grey"
                    >
                      {{ $t('periodAfterRecivedVoucher') }}
                      {{ voucher.expirationAt }} {{$t('days')}}
                    </p>
                    <p v-else-if="voucher.expirationAt === ''">
                      <strong>{{ $t('startFrom') }} : </strong>
                      {{ startAtShow(voucher.startAt) }} -
                      {{ endAtShow(voucher.endAt) }}
                    </p>
                  </CCardBody>
                </td>
              </tr>
            </table>
          </CCard>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol md="4" v-if="loadingButton === true">
            <CButton block color="success" @click="sendVoucher()">
              {{ $t('confirm') }}
            </CButton>
          </CCol>
          <CCol md="4" v-else-if="loadingButton === false">
            <CButton block color="success" disabled>
              <CSpinner color="light" size="sm" /> {{ $t('loading') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="dark" @click="popupModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>

    <CModal :show.sync="imgModal" centered header="false" :footer="footer">
      <CRow class="justify-content-center col-md-12">
        <CCard
          color="white"
          text-color="dark"
          class="text-center col-md-12"
          body-wrapper
        >
          <CCardBody color="light">
            <VueCropper
              v-show="selectedFile"
              ref="cropper"
              :src="selectedFile"
              alt="Source Image"
            >
            </VueCropper>
          </CCardBody>
        </CCard>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center col-md-12">
          <CCol md="4" v-if="loadingButton === true">
            <CButton block color="success" @click="editImg()">
              {{ $t('submit') }}
            </CButton>
          </CCol>
          <CCol md="4" v-else-if="loadingButton === false">
            <CButton block color="success" disabled>
              <CSpinner color="light" size="sm" /> {{ $t('loading') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="light" @click="imgModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>

    <CModal :show.sync="voucherModal" centered header="false" :footer="footer">
      <CRow>
        <CCol md="12">
          <CCard>
            <CCardBody v-if="vouchers.length === 0" class="text-center">
              <img
                alt=""
                src="../../../../../public/img/reward/voucher.png"
                class="img-fluid"
                width="30%"
              />
              <h5 class="text-black">
                {{ $t('haveNoVoucher') }}
                <router-link to="/voucher/create">
                  <strong> {{ $t('here') }} </strong>
                </router-link>
              </h5>
            </CCardBody>
            <CListGroup>
              <div v-for="(voucher, index) in vouchers" :key="index">
                <CListGroupItem
                  style="border-radius:0px"
                  class="list-group-item-action cursor"
                  v-if="voucher.deleted_at === null"
                >
                  <table style="width: 100%" aria-describedby="">
                    <tr @click="voucherDetail(voucher)">
                      <th scope="col" style="width:25%" class="text-center">
                        <img alt="" class="img-fluid" :src="voucher.imgUrl" />
                      </th>
                      <td style="width: 3%"></td>
                      <td class="text-left">
                        <tr>
                          <span class="font-weight-normal text-black">
                            {{ voucher.title }}
                          </span>
                        </tr>
                        <tr>
                          <p>
                            <strong>{{ $t('voucherValue') }} : </strong> {{ voucher.value }} {{ $t('baht') }}
                          </p>
                          <p
                            v-if="voucher.expirationAt !== ''"
                            class="font-weight-normal"
                          >
                            {{ $t('periodAfterRecivedVoucher') }}
                            {{ voucher.expirationAt }} {{ $t('days') }}
                          </p>
                          <p v-else-if="voucher.expirationAt === ''">
                            <strong>{{ $t('startFrom') }} : </strong>
                            {{ startAtShow(voucher.startAt) }} -
                            {{ endAtShow(voucher.endAt) }}
                          </p>
                        </tr>
                      </td>
                    </tr>
                  </table>
                </CListGroupItem>
              </div>
            </CListGroup>
          </CCard>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center col-md-12"></CRow>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import moment from 'moment'
import crm from '@/services/crm'
import pos from '@/services/pos'
import service from '@/services/service'
import util from '@/util/util'

export default {
  components: { VueCropper },
  data() {
    return {
      imgModal: false,
      selectedFile: '',
      cropedImage: '',
      loadingButton: true,
      alert: '',
      url: '',
      remark: '',
      startAt: '',
      endAt: '',
      expirationAt: '',
      value: 0,
      popupModal: false,
      footer: '',
      image: '',
      transferSlipImgUrl: '',
      isSaveSuccess: '',
      keyword: '',
      memberdata: '',
      member: '',
      memberObjectId: '',
      avatarUrl: '../img/avatar.png',
      voucherModal: false,
      vouchers: [],
      voucher: '',
      voucherObjectId: '',
      showtime: 3,
      paymentMethod: '',
      paymentMethodOther: '',
      amount: null,
      note: '',
      linemember: 'https://cdn-icons-png.flaticon.com/512/2111/2111498.png',
    }
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
  },
  mounted() {
    this.getVoucher()
  },
  methods: {
    ...util,
    getPaymentMethodOptions(){
      let paymentMethod = [
        { value: 'cash', label: this.$i18n.t('paymentType0') },
        { value: 'promptpay', label: this.$i18n.t('paymentType4') },
        { value: 'credit',  label: this.$i18n.t('paymentType1') },
        { value: 'other', label: this.$i18n.t('paymentType10') },
      ]
      return paymentMethod
    },
    startAtShow(startAt) {
      return moment(startAt).format('DD/MM/YYYY')
    },
    endAtShow(endAt) {
      return moment(endAt).format('DD/MM/YYYY')
    },
    getVoucher() {
      const uid = this.uid

      crm({
        method: 'get',
        url: '/api/v1.0/' + uid + '/voucher/get',
      })
        .then((response) => {
          this.vouchers = response.data.data.documents
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    voucherDetail(voucher) {
      this.voucher = voucher
      this.voucherObjectId = voucher.objectId
      this.voucherModal = false
    },
    memberDetail(item) {
      this.member = item
      this.memberObjectId = item.objectId
    },
    getMember(page = 1) {
      this.memberObjectId = ''
      this.searchLoadingButton = false
      this.loadingButton = false
      const uid = this.uid

      const params = {
        search: this.keyword,
        page: page,
        limit: 5,
      }

      service
        .get('/shop/v1.0/getcustomer/' + uid, { params })
        .then((res) => {
          this.memberdata = res.data.data
          this.loadingButton = true
        })
        .catch((e) => {
          console.error(e)
        })
    },
    handlePopup() {
      let validateData = this.validateData()

      if (validateData === true) {
        this.popupModal = true
      } else {
        this.popupModal = false
      }
    },
    validateData() {
      if (this.voucherObjectId === '' || this.memberObjectId === '') {
        this.alert = this.$i18n.t('validateInput')
        return false
      } else {
        if (this.paymentMethod === 'other' && this.paymentMethodOther === '') {
          this.alert = this.$i18n.t('validateInput')
          return false
        } else {
          this.alert = ''
          return true
        }
      }
    },
    editImg() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.uploadImage(this.cropedImage)
    },
    onFileSelect(e) {
      const file = e[0]

      this.imgModal = true

      this.mime_type = file.type
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    handleImage(e) {
      const file = e.target.files[0]

      this.createBase64Img(file)
    },
    createBase64Img(fileObject) {
      this.url = URL.createObjectURL(fileObject)
      const reader = new FileReader()

      reader.onload = (e) => {
        this.image = e.target.result
        this.uploadImage(this.image)
      }

      reader.readAsDataURL(fileObject)
    },
    uploadImage(image) {
      let data = {
        image: image,
      }

      this.loadingButton = false

      pos
        .post('/images/v1.0/upload/general/' + this.uid, data)
        .then((res) => {
          if (res.data.error.code === 0) {
            //บันทึกสำเร็จ
            let baseUrl = res.data.data.baseUrl
            let url = res.data.data.url

            this.transferSlipImgUrl = baseUrl + url
            this.url = this.transferSlipImgUrl
            this.imgModal = false
            this.loadingButton = true
          } else {
            //มีบางอย่างผิดพลาด
            console.log(res.data.error.message)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    sendVoucher() {
      this.loadingButton = false
      const uid = this.uid
      let startAt = ''
      let endAt = ''
      let userId = ''

      if (this.member.userId !== undefined) {
        userId = this.member.userId
      }

      let voucher = {
        objectId: this.voucherObjectId,
        id: this.voucher.id,
        title: this.voucher.title,
        value: this.voucher.value,
        serialNumber: this.voucher.serialNumber || '',
      }

      let member = {
        objectId: this.memberObjectId,
        name: this.member.name,
        userId: userId,
      }

      if (this.voucher.expirationAt !== '') {
        startAt = new Date()
        endAt = moment()
          .add(this.voucher.expirationAt, 'days')
          .toDate()
      } else {
        startAt = this.voucher.startAt
        endAt = this.voucher.endAt
      }

      if (this.paymentMethod === 'other') {
        this.paymentMethod = this.paymentMethodOther
      }

      let payment = {
        paymentMethod: this.paymentMethod,
        amount: Number(this.amount),
        note: this.note,
        transferSlipImgUrl: this.transferSlipImgUrl,
      }

      let data = {
        uid: uid,
        voucher: voucher,
        voucherObjectId: this.voucherObjectId,
        member: member,
        memberObjectId: this.memberObjectId,
        userId: userId,
        remark: this.remark,
        voucherCode: util.generateVoucherCode(),
        startAt: startAt,
        endAt: endAt,
        isUsed: false,
        payment: payment,
      }

      crm
        .post('/api/v1.0/' + uid + '/voucher/send', data)
        .then((res) => {
          if (res.data.error.code === 0) {
            this.isSaveSuccess =
              this.$i18n.t('sendVoucherComplete') + this.member.name + this.$i18n.t('completely')
            this.loadingButton = true
            this.popupModal = false
            this.$router.go()
          } else {
            alert(this.$i18n.t('errorAlert'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
